<template>
  <div v-if="visible" class="popup-overlay1" @touchstart="startDrag" @touchend="stopDrag" @touchmove.prevent="dragPopup">
    <div class="popup-content1" :style="{ top: popupTop + 'px', left: popupLeft + 'px' }">
      <button class="close-button1" @click="closePopup">&times;</button>
      <h2 class="popup-title">จัดการที่อยู่</h2>

      <!-- ฟิลด์ค้นหา -->
      <div class="search-bar">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="ค้นหาที่อยู่..."
          class="search-input"
        />
        <i class="fas fa-search search-icon"></i>
      </div>

      <!-- ส่วนแสดงรายการที่อยู่ -->
      <div class="address-list">
        <div v-for="address in filteredAddresses" :key="address.ID" class="address-card">
          <div class="address-content">
            <p class="address-thai">{{ address.ADDRESS_THAI }}</p>
            <p class="address-eng">{{ address.ADDRESS_ENG }}</p>
          </div>
          <div class="address-actions">
            <button class="edit-button" @click="startEditing(address)">
              <i class="fas fa-edit"></i> แก้ไข
            </button>
            <button class="delete-button" @click="deleteAddress(address.ID)">
              <i class="fas fa-trash-alt"></i> ลบ
            </button>
          </div>
        </div>
        <!-- ข้อความเมื่อไม่พบที่อยู่ -->
        <div v-if="filteredAddresses.length === 0" class="no-addresses">
          ไม่พบที่อยู่ที่ตรงกับการค้นหา
        </div>
      </div>

      <!-- ฟอร์มสำหรับเพิ่ม/แก้ไขที่อยู่ -->
      <div class="popup-body1">
        <form @submit.prevent="isEditing ? updateData() : insertData()">
          <div class="form-row">
            <label class="label1">ที่อยู่ (ภาษาไทย):</label>
            <textarea class="textbox1" v-model="ADDRESS_THAI" placeholder="กรอกที่อยู่ภาษาไทย" rows="4"></textarea>
          </div>

          <div class="form-row">
            <label class="label1">Address (English):</label>
            <textarea class="textbox1" v-model="ADDRESS_ENG" placeholder="Enter address in English" rows="4"></textarea>
          </div>

          <div class="form-row1">
            <button type="submit" class="btn-step-insert">
              {{ isEditing ? 'บันทึกการแก้ไข' : 'เพิ่มที่อยู่' }}
            </button>
            <button v-if="isEditing" type="button" class="btn-cancel" @click="cancelEditing">
              ยกเลิก
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    ADDRESS_THAI: '',
    ADDRESS_ENG: '',
    addresses: [],
    searchQuery: '',
    isEditing: false,
    currentEditId: null,
    isDragging: false,
    touchX: 0,
    touchY: 0,
    popupTop: 0,
    popupLeft: 0,
  };
  },
  computed: {
    filteredAddresses() {
      if (!this.searchQuery) {
        return this.addresses;
      }
      const query = this.searchQuery.toLowerCase();
      return this.addresses.filter((address) => {
        return (
          address.ADDRESS_THAI.toLowerCase().includes(query) ||
          address.ADDRESS_ENG.toLowerCase().includes(query)
        );
      });
    },
  },
  methods: {
    async fetchAddresses() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/api/addresses'
        );
        this.addresses = response.data;
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    },
    async insertData() {
      const toast = useToast();
      try {
        const formData = {
          ADDRESS_THAI: this.ADDRESS_THAI,
          ADDRESS_ENG: this.ADDRESS_ENG,
        };

        const response = await axios.post(
          process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/insertAddress',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.data.message) {
          toast.success(response.data.message);
        }
        // หลังจากเพิ่มที่อยู่ใหม่ ให้ดึงข้อมูลที่อยู่อีกครั้ง
        await this.fetchAddresses();
        // ล้างฟิลด์กรอกข้อมูล
        this.ADDRESS_THAI = '';
        this.ADDRESS_ENG = '';
        // แจ้งคอมโพเนนต์หลักให้อัปเดต
        this.$emit('address-updated');
      } catch (error) {
        console.error('There was an error!', error);
        toast.error('มีข้อผิดพลาดในการส่งข้อมูล กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน');
      }
    },
    startEditing(address) {
      this.isEditing = true;
      this.currentEditId = address.ID;
      this.ADDRESS_THAI = address.ADDRESS_THAI;
      this.ADDRESS_ENG = address.ADDRESS_ENG;
    },
    async updateData() {
      const toast = useToast();
      try {
        const formData = {
          ADDRESS_THAI: this.ADDRESS_THAI,
          ADDRESS_ENG: this.ADDRESS_ENG,
        };

        await axios.put(
          process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + `/api/addresses/${this.currentEditId}`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        toast.success('แก้ไขที่อยู่เรียบร้อยแล้ว');
        // หลังจากแก้ไขที่อยู่ ให้ดึงข้อมูลที่อยู่อีกครั้ง
        await this.fetchAddresses();
        // รีเซ็ตฟอร์มและสถานะ
        this.isEditing = false;
        this.currentEditId = null;
        this.ADDRESS_THAI = '';
        this.ADDRESS_ENG = '';
        // แจ้งคอมโพเนนต์หลักให้อัปเดต
        this.$emit('address-updated');
      } catch (error) {
        console.error('Error updating address!', error);
        toast.error('มีข้อผิดพลาดในการแก้ไขข้อมูล กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน');
      }
    },
    cancelEditing() {
      this.isEditing = false;
      this.currentEditId = null;
      this.ADDRESS_THAI = '';
      this.ADDRESS_ENG = '';
    },
    async deleteAddress(addressId) {
      const toast = useToast();
      if (confirm('คุณแน่ใจว่าต้องการลบที่อยู่นี้หรือไม่?')) {
        try {
          await axios.delete(
            process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + `/api/addresses/${addressId}`
          );
          toast.success('ลบที่อยู่เรียบร้อยแล้ว');
          // หลังจากลบที่อยู่ ให้ดึงข้อมูลที่อยู่อีกครั้ง
          await this.fetchAddresses();
          // แจ้งคอมโพเนนต์หลักให้อัปเดต
          this.$emit('address-deleted', addressId);
        } catch (error) {
          console.error('Error deleting address:', error);
          toast.error('มีข้อผิดพลาดในการลบที่อยู่');
        }
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.touchX = event.touches[0].clientX;
      this.touchY = event.touches[0].clientY;
    },
    stopDrag() {
      this.isDragging = false;
    },
    dragPopup(event) {
      if (this.isDragging) {
        const deltaX = event.touches[0].clientX - this.touchX;
        const deltaY = event.touches[0].clientY - this.touchY;
        this.popupTop += deltaY;
        this.popupLeft += deltaX;
        this.touchX = event.touches[0].clientX;
        this.touchY = event.touches[0].clientY;
      }
    },
    closePopup() {
      this.$emit('close');
    },
  },
  mounted() {
    this.fetchAddresses();
  },
};
</script>



<style scoped>
@import '@fortawesome/fontawesome-free/css/all.css';
.search-bar {
  position: relative;
  margin: 0 auto 15px auto;
  width: 400px;
}

.search-input {
  width: 100%;
  padding: 10px 40px 10px 15px; /* เพิ่มช่องว่างสำหรับไอคอน */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px; /* ทำให้มุมโค้งมน */
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 18px;
}
.popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content1 {
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
  position: relative;
  width: 80%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  cursor: grab;
}

.close-button1 {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-title {
  margin-top: 0;
  margin-bottom: 20px;
  color: black;
  text-align: center;
}

.address-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.address-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.address-content {
  flex: 1;
  margin-right: 20px;
  text-align: left; 
}

.address-thai,
.address-eng {
  margin: 0;
  color: #333;
}

.address-thai {
  font-weight: bold;
}

.address-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-button,
.delete-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 12px;
}

.delete-button {
  background-color: red;
}

.btn-cancel {
  margin-top: 10px;
  border-radius: 7px;
  font-size: 16px;
  color: white;
  padding: 10px;
  background-color: gray;
  width: 100%;
  text-align: center;
  border: none;
  cursor: pointer;
}

.popup-body1 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-row,
.form-row1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.label1 {
  margin-bottom: 5px;
  color: black;
  text-align: left;
}

.textbox1 {
  width: 100%;
  height: 100px;
  padding: 10px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-step-insert {
  margin-top: 10px;
  border-radius: 7px;
  font-size: 18px;
  color: white;
  padding: 10px;
  background-color: rgb(44, 44, 193);
  width: 100%;
  text-align: center;
  border: none;
  cursor: pointer;
}

@media (max-width: 600px) {
  .popup-content1 {
    width: 90%;
  }
}
</style>
