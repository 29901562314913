<template>
  <div v-if="visible" class="popup-overlay1">
    <div class="popup-content1" :style="{ top: popupTop + 'px', left: popupLeft + 'px' }">
      <button class="close-button1" @click="closePopup">&times;</button>
      <h2 class="popup-title">จัดการข้อมูล BU</h2>
      <div class="search-bar">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="ค้นหา BU..."
          class="search-input"
        />
        <i class="fas fa-search search-icon"></i>
      </div>
      <div class="header-actions">
  <button class="add-bu-btn" @click="scrollToForm">
    <i class="fas fa-plus"></i> เพิ่ม BU
  </button>
</div>
      <!-- BU List -->
      <div class="bu-list">
        <div v-for="bu in filteredBUs" :key="bu.BU_NAME" class="bu-card">
          <div class="bu-content">
            <div class="bu-logo-container">
              <img :src="'data:image/png;base64,' + bu.Logo" alt="Logo" class="bu-logo"/>
            </div>
            <div class="bu-details">
              <p class="bu-name">{{ bu.BU_NAME }}</p>
              <p class="company-name">{{ bu.COMPANY_NAMETHAI }}</p>
              <p class="company-name-eng">{{ bu.COMPANY_NAMEENG }}</p>
              <p class="tax-id">TAX ID: {{ bu.TAX_ID }}</p>
            </div>
            <div class="bu-preview">
              <img :src="'data:image/png;base64,' + bu.BU_BG" alt="Background" class="bu-background"/>
              <div class="color-preview" :style="{ backgroundColor: bu.BG_COLOR }"></div>
            </div>
          </div>
          <div class="bu-actions">
            <button class="edit-button" @click="startEditing(bu)">
              <i class="fas fa-edit"></i> แก้ไข
            </button>
            <button class="delete-button" @click="deleteBU(bu.BU_NAME)">
              <i class="fas fa-trash-alt"></i> ลบ
            </button>
          </div>
        </div>
   
        <div v-if="filteredBUs.length === 0" class="no-bu">
          ไม่พบ BU ที่ตรงกับการค้นหา
        </div>
      </div>

      <!-- Add/Edit Form -->
      <div class="form-container" :class="{ 'editing': isEditing }">
        <h3 class="form-title">{{ isEditing ? 'แก้ไขข้อมูล BU' : 'เพิ่ม BU ใหม่' }}</h3>
        <form @submit.prevent="isEditing ? updateData() : insertData()">
          <div class="form-grid">
            <div class="form-column">
              <div class="form-row">
                <label class="label1">BU Name:</label>
                <input 
                  type="text" 
                  class="textbox1" 
                  v-model="BU_NAME" 
                  placeholder="ชื่อ BU" 
                  required 
                  :disabled="isEditing"
                />
              </div>

              <div class="form-row">
                <label class="label1">ชื่อบริษัท (ไทย):</label>
                <input 
                  type="text" 
                  class="textbox1" 
                  v-model="COMPANY_NAMETHAI" 
                  placeholder="ชื่อบริษัท (ภาษาไทย)" 
                  required
                />
              </div>

              <div class="form-row">
                <label class="label1">Company Name (English):</label>
                <input 
                  type="text" 
                  class="textbox1" 
                  v-model="COMPANY_NAMEENG" 
                  placeholder="Company Name" 
                  required
                />
              </div>

              <div class="form-row">
                <label class="label1">TAX ID:</label>
                <input 
                  type="text" 
                  class="textbox1" 
                  v-model="TAX_ID" 
                  placeholder="TAX ID" 
                  required
                />
              </div>

              <div class="form-row">
                <label class="label1">Background Color:</label>
                <div class="color-picker-container">
                  <input type="color" v-model="BG_COLOR" class="color-picker"/>
                  <span class="color-value">{{ BG_COLOR }}</span>
                </div>
              </div>
            </div>

            <div class="form-column">
              <div class="form-row">
                <label class="label1">Background Image:</label>
                <div class="image-preview-container">
                  <div class="image-preview-wrapper">
                    <img v-if="previewBG" :src="previewBG" class="image-preview"/>
                    <div v-else class="no-image">
                      <i class="fas fa-image"></i>
                      <span>No background image selected</span>
                    </div>
                  </div>
                  <input 
                    type="file" 
                    ref="bgImage" 
                    accept="image/*" 
                    @change="handleBGChange" 
                    class="file-input"
                  />
                  <button type="button" class="select-image-btn" @click="$refs.bgImage.click()">
                    <i class="fas fa-upload"></i> Select Background
                  </button>
                </div>
              </div>

              <div class="form-row">
                <label class="label1">Company Logo:</label>
                <div class="image-preview-container">
                  <div class="image-preview-wrapper">
                    <img v-if="previewLogo" :src="previewLogo" class="image-preview"/>
                    <div v-else class="no-image">
                      <i class="fas fa-image"></i>
                      <span>No logo selected</span>
                    </div>
                  </div>
                  <input 
                    type="file" 
                    ref="logoImage" 
                    accept="image/*" 
                    @change="handleLogoChange" 
                    class="file-input"
                  />
                  <button type="button" class="select-image-btn" @click="$refs.logoImage.click()">
                    <i class="fas fa-upload"></i> Select Logo
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-actions">
            <button type="submit" class="btn-step-insert">
              {{ isEditing ? 'บันทึกการแก้ไข' : 'เพิ่ม BU' }}
            </button>
            <button 
              v-if="isEditing" 
              type="button" 
              class="btn-cancel" 
              @click="cancelEditing"
            >
              ยกเลิก
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BU_NAME: '',
      COMPANY_NAMETHAI: '',
      COMPANY_NAMEENG: '',
      TAX_ID: '',
      BG_COLOR: '#000000',
      bgImage: null,
      logoImage: null,
      previewBG: null,
      previewLogo: null,
      buList: [],
      searchQuery: '',
      isEditing: false,
      currentEditBU: null,
      isDragging: false,
      touchX: 0,
      touchY: 0,
      popupTop: 0,
      popupLeft: 0,
    };
  },
  computed: {
    filteredBUs() {
      if (!this.searchQuery) return this.buList;
      const query = this.searchQuery.toLowerCase();
      return this.buList.filter(bu => 
        bu.BU_NAME.toLowerCase().includes(query) ||
        bu.COMPANY_NAMETHAI.toLowerCase().includes(query) ||
        bu.COMPANY_NAMEENG.toLowerCase().includes(query) ||
        bu.TAX_ID.includes(query)
      );
    },
  },
  methods: {
    scrollToForm() {
    this.$nextTick(() => {
      const formElement = document.querySelector('.form-container');
      if (formElement) {
        formElement.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    });
  },
  showAddForm() {
    this.showForm = true;
    this.scrollToForm();
  },
    async fetchBUs() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/data/bu_name`);
        const buDetailsPromises = response.data.map(async bu => {
          const detailsResponse = await axios.get(
            `${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/api/data?buName=${bu.BU_NAME}`
          );
          return { ...bu, ...detailsResponse.data };
        });
        this.buList = await Promise.all(buDetailsPromises);
      } catch (error) {
        console.error('Error fetching BUs:', error);
        useToast().error('ไม่สามารถดึงข้อมูล BU ได้');
      }
    },

    handleBGChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 3 * 1024 * 1024) { // 3MB limit
          useToast().error('ขนาดไฟล์ต้องไม่เกิน 3MB');
          return;
        }
        this.bgImage = file;
        this.previewBG = URL.createObjectURL(file);
      }
    },

    handleLogoChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 1 * 1024 * 1024) { // 1MB limit
          useToast().error('ขนาดไฟล์ต้องไม่เกิน 1MB');
          return;
        }
        this.logoImage = file;
        this.previewLogo = URL.createObjectURL(file);
      }
    },

    async insertData() {
      const toast = useToast();
      if (!this.validateForm()) return;

      try {
        const formData = new FormData();
        formData.append('BU_NAME', this.BU_NAME);
        formData.append('COMPANY_NAMETHAI', this.COMPANY_NAMETHAI);
        formData.append('COMPANY_NAMEENG', this.COMPANY_NAMEENG);
        formData.append('TAX_ID', this.TAX_ID);
        formData.append('BG_COLOR', this.BG_COLOR);
        
        if (this.bgImage) formData.append('BG', this.bgImage);
        if (this.logoImage) formData.append('Logo', this.logoImage);

        await axios.post(
          `${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/insert`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );

        toast.success('เพิ่ม BU สำเร็จ');
        this.resetForm();
        await this.fetchBUs();
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.response?.data?.error || 'เกิดข้อผิดพลาดในการเพิ่ม BU');
      }
    },

    async updateData() {
      const toast = useToast();
      if (!this.validateForm()) return;

      try {
        const formData = new FormData();
        formData.append('COMPANY_NAMETHAI', this.COMPANY_NAMETHAI);
        formData.append('COMPANY_NAMEENG', this.COMPANY_NAMEENG);
        formData.append('TAX_ID', this.TAX_ID);
        formData.append('BG_COLOR', this.BG_COLOR);
        
        if (this.bgImage) formData.append('BG', this.bgImage);
        if (this.logoImage) formData.append('Logo', this.logoImage);

        await axios.put(
          `${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/bu/${this.BU_NAME}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );

        toast.success('อัปเดต BU สำเร็จ');
        this.resetForm();
        await this.fetchBUs();
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.response?.data?.error || 'เกิดข้อผิดพลาดในการอัปเดต BU');
      }
    },

    validateForm() {
      const toast = useToast();
      if (!this.BU_NAME || !this.COMPANY_NAMETHAI || !this.COMPANY_NAMEENG || !this.TAX_ID) {
        toast.error('กรุณากรอกข้อมูลให้ครบถ้วน');
        return false;
      }
      if (!this.isEditing && (!this.bgImage || !this.logoImage)) {
        toast.error('กรุณาเลือกรูปภาพให้ครบถ้วน');
        return false;
      }
      return true;
    },

    startEditing(bu) {
      this.isEditing = true;
      this.currentEditBU = bu.BU_NAME;
      this.BU_NAME = bu.BU_NAME;
      this.COMPANY_NAMETHAI = bu.COMPANY_NAMETHAI;
      this.COMPANY_NAMEENG = bu.COMPANY_NAMEENG;
      this.TAX_ID = bu.TAX_ID;
      this.BG_COLOR = bu.BG_COLOR;
      this.previewBG = `data:image/png;base64,${bu.BU_BG}`;
      this.previewLogo = `data:image/png;base64,${bu.Logo}`;
      this.scrollToForm();
    },

    resetForm() {
      this.BU_NAME = '';
      this.COMPANY_NAMETHAI = '';
      this.COMPANY_NAMEENG = '';
      this.TAX_ID = '';
      this.BG_COLOR = '#000000';
      this.bgImage = null;
      this.logoImage = null;
      this.previewBG = null;
      this.previewLogo = null;
      this.isEditing = false;
      this.currentEditBU = null;
    },

    async deleteBU(buName) {
      const toast = useToast();
      if (confirm(`คุณแน่ใจหรือไม่ที่จะลบ BU: ${buName}?`)) {
        try {
          await axios.delete(`${process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT}/bu/${buName}`);
          toast.success('ลบ BU สำเร็จ');
          await this.fetchBUs();
        } catch (error) {
          console.error('Error:', error);
          toast.error('เกิดข้อผิดพลาดในการลบ BU');
        }
      }
    },
    cancelEditing() {
    // รีเซ็ตสถานะการแก้ไข
    this.isEditing = false;
    this.currentEditBU = null;

    // รีเซ็ตค่าในฟอร์ม
    this.BU_NAME = '';
    this.COMPANY_NAMETHAI = '';
    this.COMPANY_NAMEENG = '';
    this.TAX_ID = '';
    this.BG_COLOR = '#000000';
    
    // รีเซ็ตรูปภาพ
    if (this.previewBG) {
      URL.revokeObjectURL(this.previewBG);
      this.previewBG = null;
    }
    if (this.previewLogo) {
      URL.revokeObjectURL(this.previewLogo);
      this.previewLogo = null;
    }
    this.bgImage = null;
    this.logoImage = null;

    if (this.$refs.bgImage) {
      this.$refs.bgImage.value = '';
    }
    if (this.$refs.logoImage) {
      this.$refs.logoImage.value = '';
    }
  },

    closePopup() {
    this.cancelEditing();
    this.$emit('close');
  },
  },
  mounted() {
    this.fetchBUs();
  },
  beforeUnmount() {
    if (this.previewBG) URL.revokeObjectURL(this.previewBG);
    if (this.previewLogo) URL.revokeObjectURL(this.previewLogo);
  },
};
</script>

<style scoped>
@import '@fortawesome/fontawesome-free/css/all.css';

.popup-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content1 {
  background: white;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.close-button1 {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s;
}

.close-button1:hover {
  color: #333;
}

.popup-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

.search-bar {
  margin-bottom: 30px;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 12px 40px 12px 20px;
  border: 2px solid #e0e0e0;
  border-radius: 25px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.1);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 18px;
}

.bu-list {
  margin-bottom: 40px;
}

.bu-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  min-height: 180px;
}

.bu-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.bu-content {
  display: grid;
  grid-template-columns: 80px 1fr 200px;
  gap: 20px;
  align-items: center;
  padding: 10px;
}

.bu-logo-container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
}

.bu-logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.bu-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bu-name {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.company-name {
  font-size: 16px;
  color: #555;
  margin: 0;
}

.company-name-eng {
  font-size: 14px;
  color: #777;
  margin: 0;
}

.tax-id {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0 0;
}

.bu-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.bu-background {
  width: 180px; 
  height: 120px; 
  object-fit: contain; 
  border-radius: 8px;
  background-color: #f8f9fa;
  padding: 5px;
  border: 1px solid #e0e0e0;
}

.color-preview {
  width: 180px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.bu-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.edit-button, .delete-button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.2s;
}

.edit-button {
  background-color: #007bff;
  color: white;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.form-container {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 25px;
  margin-top: 30px;
}

.form-title {
  font-size: 20px;
  color: #333;
  margin: 0 0 25px 0;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label1 {
  font-size: 14px;
  color: #555;
  font-weight: 500;
}

.textbox1 {
  padding: 10px 12px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.textbox1:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.1);
}

.color-picker-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.color-picker {
  width: 100px;
  height: 40px;
  padding: 2px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
}

.color-value {
  font-size: 14px;
  color: #666;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-preview-wrapper {
  width: 100%;
  height: 200px;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.no-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #888;
}

.no-image i {
  font-size: 40px;
}

.file-input {
  display: none;
}

.select-image-btn {
  padding: 10px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.2s;
  font-size: 14px;
}

.select-image-btn:hover {
  background-color: #5a6268;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.btn-step-insert {
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-step-insert:hover {
  background-color: #218838;
}

.btn-cancel {
  padding: 12px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-cancel:hover {
  background-color: #5a6268;
}

.no-bu {
  text-align: center;
  padding: 30px;
  color: #666;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 20px 0;
}
.header-actions {
  display: flex;
  justify-content: flex-end;
  margin: -20px 0 20px 0;
}

.add-bu-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.add-bu-btn:hover {
  background-color: #218838;
  transform: translateY(-1px);
}
@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
  
  .popup-content1 {
    padding: 20px;
    width: 95%;
  }
  
  .bu-content {
    grid-template-columns: 1fr;
    text-align: center;
  }
  
  .bu-logo-container {
    margin: 0 auto;
  }
  
  .bu-actions {
    justify-content: center;
  }
  .bu-content {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .bu-preview {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .bu-background {
    width: 100%;
    max-width: 300px;
    height: 200px;
  }

  .color-preview {
    width: 100%;
    max-width: 300px;
  }
}
</style>