<template>
    <div class="manage-users">
      <h1>Manage Users</h1>
      <button class="btn add-user-btn" @click="showAddUserForm = !showAddUserForm">{{ showAddUserForm ? 'Cancel' : 'Add User' }}</button>
  
      <div class="search-container">
        <input v-model="searchQuery" @input="filterUsers" placeholder="Search users">
        <button class="btn search-btn" @click="filterUsers">Search</button>
      </div>
  
      <div v-if="showAddUserForm" class="user-form">
        <h2>Add User</h2>
        <form @submit.prevent="addUser">
          <input v-model="newUser.username" placeholder="Username" required>
          <input v-model="newUser.password" type="password" placeholder="Password" required>
          <button class="btn" type="submit">Add</button>
        </form>
      </div>
  
      <div v-if="showEditUserForm" class="user-form">
        <h2>Edit User</h2>
        <form @submit.prevent="updateUser">
          <input v-model="currentUser.username" placeholder="Username" required>
          <input v-model="currentUser.password" type="password" placeholder="Password" required>
          <button class="btn" type="submit">Update</button>
        </form>
      </div>
  
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" :key="user.id">
              <td>{{ user.username }}</td>
              <td>{{ user.created_at }}</td>
              <td>{{ user.updated_at }}</td>
              <td>
                <button class="btn action-btn edit-btn" @click="editUser(user)">Edit</button>
                <button class="btn action-btn delete-btn" @click="deleteUser(user.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="action-buttons">
        <button class="btn create-card-btn" @click="createcard">Create Card</button>
        <button class="btn logout-btn" @click="logout">Logout</button>
      </div>
    </div>
  </template>
  
  
  
  
  <script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  name: 'ManageUsers',
  data() {
    return {
      users: [],
      filteredUsers: [],
      searchQuery: '',
      newUser: {
        username: '',
        password: ''
      },
      currentUser: {},
      showAddUserForm: false,
      showEditUserForm: false
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      axios.get(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/api/users')
        .then(response => {
          this.users = response.data;
          this.filteredUsers = this.users;
        })
        .catch(error => {
          console.error("There was an error fetching the users!", error);
          Vue.$toast.error("There was an error fetching the users!");
        });
        
    },
    filterUsers() {
      this.filteredUsers = this.users.filter(user =>
        user.username.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    addUser() {
        const toast = useToast();
      axios.post(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + '/api/users', this.newUser)
        .then(response => {
          this.users.push(response.data);
          this.newUser = { username: '', password: '' };
          this.showAddUserForm = false;
          toast.success("User added successfully!");
        })
        .catch(error => {
          console.error("There was an error adding the user!", error);
          toast.error("There was an error adding the user!");
        });
    },
    editUser(user) {
      this.currentUser = { ...user };
      this.showEditUserForm = true;
    },
    updateUser() {
        const toast = useToast();
      axios.put(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + `/api/users/${this.currentUser.id}`, this.currentUser)
        .then(response => {
          const index = this.users.findIndex(user => user.id === this.currentUser.id);
          if (index !== -1) {
            this.users.splice(index, 1, response.data);
          }
          this.showEditUserForm = false;
          this.currentUser = {};
          toast.success("User updated successfully!");
        })
        .catch(error => {
          console.error("There was an error updating the user!", error);
          toast.error("There was an error updating the user!");
        });
    },
    deleteUser(id) {
        const toast = useToast();
      axios.delete(process.env.VUE_APP_PUBLIC_SERVICE_ENDPOINT + `/api/users/${id}`)
        .then(response => {
          this.users = this.users.filter(user => user.id !== id);
          toast.success("User deleted successfully!");
        })
        .catch(error => {
          console.error("There was an error deleting the user!", error);
          toast.error("There was an error deleting the user!");
        });
    },
    logout() {
      localStorage.removeItem('loggedIn');
      window.location.href = '/';
    },
    createcard() {
      window.location.href = '/create-card';
    }
  }
};
</script>
  
  
<style scoped>
.manage-users {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.add-user-btn {
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
}

.search-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.search-btn:hover {
  opacity: 0.9;
}

.user-form {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.user-form h2 {
  margin-top: 0;
}

.user-form form {
  display: flex;
  flex-direction: column;
}

.user-form input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn {
  padding: 10px 15px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.btn.delete-btn {
  background-color: #dc3545;
}

.btn.edit-btn {
  background-color: #ffc107;
  color: black;
}

.btn:hover {
  opacity: 0.9;
}

.table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.action-btn {
  margin-right: 10px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.create-card-btn {
  background-color: #28a745;
  margin-right: 10px;
}

.logout-btn {
  background-color: #dc3545;
}

</style>
  