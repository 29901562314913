import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // นำเข้า router

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoffee, faTimes, faQuestionCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// เพิ่มไอคอนที่ต้องการใช้ใน library
library.add(faCoffee, faTimes, faQuestionCircle, faPlusCircle);

const app = createApp(App);
app.use(router); // ใช้ router
app.use(Toast, {
    position: POSITION.TOP_RIGHT,
    timeout: 3000
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
