<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: Prompt;
  src: url(prompt.ttf);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

body {
  background-color: #333333;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: Prompt;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: Prompt;
}
</style>
