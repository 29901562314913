<template>
  <div
    v-if="visible"
    class="popup-overlay"
    @touchstart="startDrag"
    @touchend="stopDrag"
    @touchmove.prevent="dragPopup"
  >
    <div v-if="visible" class="popup-overlay">
      <div
        class="popup-content"
        :style="{ top: popupTop + 'px', left: popupLeft + 'px' }"
      >
        <button class="close-button" @click="closePopup">&times;</button>
        <div class="popup-header">
          <h2>How To Use <font-awesome-icon icon="question-circle" /></h2>
        </div>
        <div class="popup-body flex">
          <div class="box">
            <h4>1. Select BU and Fill in employee information</h4>
            <img class="popup-image" src="/info.jpeg" alt="Image" />
          </div>
          <div class="popup-body">
            <h4>2. Select an image that has been saved on the device.</h4>
            <img class="popup-image" src="/selectimg.jpeg" alt="Image" />
          </div>
        </div>
        <div class="popup-body flex">
          <div class="box">
            <h4>3. Select the desired employee photo and press confirm.</h4>
            <br /><br />
            <img class="popup-image" src="/employee.jpeg" alt="Image" />
          </div>
          <div class="popup-body">
            <h4>
              4. When you have chosen a picture I will give you a picture. You
              can crop the image in the desired area and move the square frame
              to the desired position. Zoom in, zoom out by scrolling the mouse.
            </h4>
            <img class="popup-image" src="/cropimg.jpeg" alt="Image" />
          </div>
        </div>
        <div class="popup-body flex">
          <div class="box">
            <h4>
              5. When finished decorating the picture Click to preview. Then an
              example such as an employee ID card will be displayed.
            </h4>
            <img class="popup-image" src="/clickpreview.jpeg" alt="Image" />
          </div>
          <div class="popup-body">
            <h4>
              6. How to save images for use Right-click on the sample employee
              card, select "Save As...." and select the folder you want to save.
            </h4>
            <img class="popup-image" src="/saveimg.jpeg" alt="Image" />
          </div>
        </div>
        <div style="position: center">
          <div class="box">
            <h4>
              7. When finished recording Go to the saved folder. Then double
              click on the employee card image and it can be used for other
              work.
            </h4>
            <img
              style="height: 500px; width: 1100px"
              src="/cardpreview.jpeg"
              alt="Image"
            />
          </div>
        </div>
        <div class="popup-footer">
          <button @click="closePopup">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      touchX: 0,
      touchY: 0,
      popupTop: 0,
      popupLeft: 0,
    };
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;
      this.touchX = event.touches[0].clientX;
      this.touchY = event.touches[0].clientY;
    },
    stopDrag() {
      this.isDragging = false;
    },
    dragPopup(event) {
      if (this.isDragging) {
        const deltaX = event.touches[0].clientX - this.touchX;
        const deltaY = event.touches[0].clientY - this.touchY;
        this.popupTop += deltaY;
        this.popupLeft += deltaX;
        this.touchX = event.touches[0].clientX;
        this.touchY = event.touches[0].clientY;
      }
    },
    closePopup() {
      this.$emit("close");
    },
    mounted() {
      // ตรวจสอบความสูงของ popup body เมื่อข้อมูลเปลี่ยนแปลง
      this.$nextTick(() => {
        const popupBody = this.$refs.popupBody;
        if (popupBody.scrollHeight > popupBody.clientHeight) {
          popupBody.style.overflowY = "scroll"; // ตั้ง overflow-y เป็น scroll เมื่อมีข้อมูลเกินขนาดของ popup
        }
      });
    },
  },
};
</script>

<style>
.flex {
  display: flex;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.popup-content {
  background: white;
  padding: absolute;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  width: absolute;
  text-align: center;
  cursor: grab;
  max-height: 650px; /* กำหนดความสูงสูงสุดของ popup */
  overflow-y: auto; /* ตั้ง overflow-y เป็น auto เพื่อให้ popup มีการเลื่อน */
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.popup-header {
  margin-bottom: 15px;
}
.popup-body {
  position: center;
  flex-grow: 1; /* ให้ popup-body ขยายตามขนาดของเนื้อหา */
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
}
.popup-image {
  height: 450px;
  width: 550px;
}
.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.popup-footer button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.popup-footer button:hover {
  background-color: #0056b3;
}
.popup-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
