import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import CreateCard from '../components/CreateCard.vue'
import ManageUsers from '../components/ManageUsers.vue'

const routes = [
  { path: '/', component: Login },
  { path: '/create-card', component: CreateCard, meta: { requiresAuth: true } },
  { path: '/manage-users', component: ManageUsers, meta: { requiresAuth: true } }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('loggedIn') === 'true';  // แก้ไขตรงนี้
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/');
  } else {
    next();
  }
})

export default router
